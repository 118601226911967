@import 'custom_bootstrap_vars';
@import '~bootstrap/scss/bootstrap';

// project specific CSS goes here

////////////////////////////////
//Variables//
////////////////////////////////

// Root variables
//$body-bg: #d5dfd5;
$light: #d5dfd5;
//#f5f5dc;

// Alert colors

$white: #fff;
$mint-green: #d6e9c6;
$black: #000;
$pink: #f2dede;
$dark-pink: #eed3d7;
$red: #b94a48;

@import 'custom_bootstrap_vars';
@import 'bootstrap';
////////////////////////////////
//Alerts//
////////////////////////////////

// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error

.alert-debug {
  background-color: $white;
  border-color: $mint-green;
  color: $black;
}

.alert-error {
  background-color: $pink;
  border-color: $dark-pink;
  color: $red;
}

.nav-item {
  font-family: 'Hoefler Titling A', 'Hoefler Titling B';
}

.underlinerow td {
  border-bottom: 1px solid black;
}

.input-group-field {
  margin-right: 8px;
}

.input-group-label {
  margin-right: 4px;
}
